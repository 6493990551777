/* --------------------------------------------------------------------------------
Grid
-------------------------------------------------------------------------------- */
.landingPage {
  padding: 20px;
}


.grid {
  display: flex;
  flex: 0 1 auto;
  /* defaults to 'row' */
  flex-direction: row;
  flex-wrap: wrap;
  /* defaults to 'flex-start' */
  justify-content: flex-start;
  align-items: flex-start;
}

.grid-col {
  flex: 0 0 auto;
  padding: 0 0.5rem 0.5rem;
  box-sizing: border-box;
}

.grid-col_1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.grid-col_2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.grid-col_3 {
  flex-basis: 25%;
  max-width: 25%;
}

.grid-col_4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.grid-col_5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.grid-col_6 {
  flex-basis: 50%;
  max-width: 50%;
}

.grid-col_7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.grid-col_8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.grid-col_9 {
  flex-basis: 75%;
  max-width: 75%;
}

.grid-col_10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.grid-col_11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.grid-col_12 {
  flex-basis: 100%;
  max-width: 100%;
}
