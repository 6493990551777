/* blue #27a9e3 purp #642d92 */

.nav {
  /* flex box layout */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #642d92;
  overflow: hidden;
  justify-content: center;
}

h2 {
  padding: 5px;
}

.nav a {
  text-decoration: none;
  background-color:#642d92;

}

.navBar {
  background-color:#642d92;
}

.nav-title {
  font-size: 28px;
  font-weight: 700px;
  color: white;
  margin-bottom: 10px;
  margin-top: -5px;
}

.navLink {
  color: white;
  background-color: #642d92;
  text-align: center;
  padding: 0px 7px;
  text-decoration: none;
  font-size: 15px;
  display: flexbox;
  outline-style:groove;
  outline: 4px;
  outline-color: #27a9e3;
  margin-bottom: 10px;
  width: auto;
  

  /* these last two make the logout button in
  the nav bar look like another link */
  border: none;
  cursor: pointer;
  outline: 0;
}

.navLink:hover {
  background-color: #441f62;
  padding: 5px;
}

hr {
  background-color: #27a9e3;
  border: #27a9e3;
  border-width: 2px;
  border-style: solid;
  width: 100%;
  height: 6px;
}
